@import "../../screen.scss";

a.logo {
  display: flex;

  font-size: var(--font-size-500);
  font-weight: var(--font-medium);

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding-500);

  text-transform: uppercase;

  color: var(--color-text-300);

  &:hover {
    &, & svg {
      color: var(--color-text-100);
    }
  }
}

.name {
  @media (max-width: $screen-laptop) {
    display: none;
  }
}

.logoIcon {
  color: var(--color-text-300);

  svg {
    display: block;
  }
}
