@import "../../screen.scss";

@mixin flare-effect-on-hover {
    overflow: hidden;
    position: relative;

    &:after {
        background: #fff;
        content: "";
        height: 200%;
        left: -30%;
        opacity: 0.2;
        position: absolute;
        top: -50%;
        transform: rotate(35deg);
        transition: all 1s cubic-bezier(0.19, 1, 0.22, 1);
        width: 50px;
    }

    &:hover {
        &:after {
            left: 120%;
        }
    }

    @media (max-width: $screen-tablet) {
        &:after {
            left: -10rem;
        }

        &:hover {
            &:after {
                left: 150%;
            }
        }
    }
}

.imageCard, .imageCardVertical {
    border-radius: var(--border-radius-500);
    background: var(--color-background-highlight);
    box-shadow: inset 0 0 12px -1px rgba(88, 88, 94, 0.26), 0px 0px 6px -1px rgba(34, 33, 33, 0.493);
    transition: all 0.5s cubic-bezier(0.19, 1, 0.22, 1);

    margin-top: var(--margin-500);

    display: flex;
    flex-direction: row;
    cursor: pointer;

    @include flare-effect-on-hover;

    span {
        display: inline-block;
        transition: transform 200ms;
    }

    h3 {
        font-weight: var(--font-semibold);
        font-size: var(--font-size-750);
        line-height: var(--line-height-700);
        margin-bottom: var(--margin-100);
        color: var(--color-text-300);
        transition: transform 200ms;
    }

    p {
        color: var(--color-text-400);
        font-size: var(--font-size-600);
        line-height: var(--line-height-600);
        margin-bottom: 0;
    }

    img {
        max-width: 100%;
        height: auto;
        display: block;
        vertical-align: middle;
    }

    &:hover {
        box-shadow: inset 0 0 12px -1px rgba(88, 88, 94, 0.26), 0px 0px 9px -1px rgba(0, 0, 0, 0.4);
    }

    &:active {
        box-shadow: inset 0 0 24px -12px rgba(0, 0, 0, 0.712);
    }
}

.imageCardVertical {
    flex-direction: column;

    &:after {
        height: 300%;
        left: -150%;
    }
}

.disabled {
    pointer-events: none;
    cursor: default;
    background: none;
    box-shadow: none;
    border: 3px dashed var(--color-neutral-600);
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 100%;
    padding: var(--padding-400);
    padding-bottom: var(--padding-300);

    *:last-child {
        margin-top: auto;
    }
}

.target {
    margin-left: auto;

    color: var(--color-text-500);
    font-size: var(--font-size-600);
    line-height: var(--line-height-600);
}

@media (max-width: $screen-desktop) {
    .imageCard {
        img {
            --w: 100%;
            --ratio: 1; /* width / height */

            width: var(--w);
            height: calc(var(--w)/var(--ratio));
        }
    }

    .imageCardVertical {
        &:after {
            height: 200%;
            left: -130%;
        }
    }
}

@media (max-width: $screen-laptop) {
    .imageCard {
        img {
            --w: 100%;
            --ratio: 1; /* width / height */

            width: var(--w);
            height: calc(var(--w)/var(--ratio));
        }
    }
}


@media (max-width: $screen-tablet) {
    .imageCard {
        h3 {
            font-size: var(--font-size-700);
            line-height: var(--line-height-700);
        }

        img {
            --w: 100%;
            --ratio: 1; /* width / height */

            width: var(--w);
            height: calc(var(--w)/var(--ratio));
        }
        
        .target, p {
            font-size: var(--font-size-500);
            line-height: var(--line-height-500);
        }
    }
}

@media (max-width: $screen-mobile) {
    .imageCard {
        h3 {
            font-size: var(--font-size-700);
            line-height: var(--line-height-700);
        }

        img {
            --w: 100%;
            --ratio: 1; /* width / height */

            width: var(--w);
            height: calc(var(--w)/var(--ratio));
        }

        .target, p {
            font-size: var(--font-size-500);
            line-height: var(--line-height-500);
        }
    }
}

@media (max-width: $screen-small-mobile) {
    .imageCard {
        h3 {
            font-size: var(--font-size-700);
            line-height: var(--line-height-700);
        }

        img {
            --w: 100%;
            --ratio: 1; /* width / height */

            width: var(--w);
            height: calc(var(--w)/var(--ratio));
        }

        .target, p {
            font-size: var(--font-size-500);
            line-height: var(--line-height-500);
        }
    }
}
