
.button {
  animation: appearWithDelay 1s ease-in;
  &.bold {
    button {
      font-weight: var(--font-bold);
    }
  }
  
  &.dark {
    button {
      background-color: var(--color-dark-700);
    }
  }
  
}


@keyframes appearWithDelay {
  0% {
    opacity: 0;
    transform: translateY(0.5rem);
  }

  50% {
    opacity: 0;
    transform: translateY(0.5rem);
  }

  70% {
    opacity: 1;
    transform: translateY(0);
  }
}
