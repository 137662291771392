.loader {
  position: fixed;
  z-index: 9999;
  width: 0;
  height: 2px;
  top: 0;
  background-color: var(--color-primary-gradient-start);
  transform: translateZ(0);
  will-change: opacity, width;
}
