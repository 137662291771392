@import "../../../screen.scss";
@import "../../../components/styles/gradient-text.mixin.scss";

.problem {

    width: 100%;
    height: 500vh;

    mark {
        @include gradient-text(var(--color-primary-gradient));
    }

    h2 {
        font-size: var(--font-size-850);
        line-height: var(--line-height-750);
        font-weight: var(--font-bold);
        color: var(--color-text-300);
    }

    h3 {
        font-size: var(--font-size-750);
        line-height: var(--line-height-600);
        font-weight: var(--font-bold);
    }
}

@media (max-width: $screen-laptop) {
    .problem {
        h2 {
            font-size: var(--font-size-750);
            line-height: var(--line-height-600);
        }

        h3 {
            font-size: var(--font-size-700);
            line-height: var(--line-height-500);
        }
    }
}
