.page {
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;
  width: 100%;
}

.content {
  display: flex;
  align-items: center;
}
