@import "../../screen.scss";
@import "../styles/gradient-text.mixin.scss";

.header1 {
    font-size: var(--font-size-1000);
    font-weight: var(--font-bold);
    line-height: var(--line-height-1000);
    margin-bottom: var(--margin-400);
    color: var(--color-text-300);
}

.gradient {
    @include gradient-text(var(--color-primary-gradient));
}

.header2 {
    font-size: var(--font-size-900);
    font-weight: var(--font-bold);
    line-height: var(--line-height-900);
    margin-bottom: var(--margin-400);
    color: var(--color-text-300);
}

.header3 {
    color: var(--color-text-300);
}

.header4 {
    color: var(--color-text-200);
}

.header3, .header4 {
    font-size: inherit;
    font-weight: inherit;
    margin: 0;
}

.iconExternal {
    margin-left: var(--margin-50);
    vertical-align: middle;
    display: inline;
}

@media (max-width: $screen-mobile) {
    .header1 {
        font-size: var(--font-size-900);
        line-height: var(--line-height-900);
    }

    .header2 {
        font-size: var(--font-size-800);
        line-height: var(--line-height-800);
    }
}

@media (max-width: $screen-small-mobile) {
    .header1 {
        font-size: var(--font-size-800);
        line-height: var(--line-height-800);
    }

    .header2 {
        font-size: var(--font-size-700);
        line-height: var(--line-height-700);
    }
}
