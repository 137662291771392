@keyframes appearOnlyOpacity {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes appearFrom09 {
  0% {
    opacity: 0;
    transform: scale(0.9);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes appearFrom07 {
  0% {
    opacity: 0;
    transform: scale(0.7);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

@keyframes appearUp {
  0% {
    opacity: 0;
    transform: translateY(0.5rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes appearUpReverse {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(0.5rem);
  }
}

@keyframes appearDown {
  0% {
    opacity: 0;
    transform: translateY(-2rem);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes appearToLeft {
  0% {
    opacity: 0;
    transform: translateX(2rem);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes appearToRight {
  0% {
    opacity: 0;
    transform: translateX(-2rem);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
