@import "../../../../screen.scss";

.container {
  display: flex;
  align-items: flex-start;
  justify-content: center;

  min-height: 48px;

  margin-top: var(--margin-700);

  & > * + * {
    margin-left: var(--padding-500);
  }

  @media (max-width: $screen-desktop) {
    min-height: 40px;
  }

  @media (max-width: $screen-mobile) {
    align-self: stretch;
    flex-direction: column;
    align-items: center;

    margin-top: var(--margin-400);

    & > * + * {
      margin-top: var(--margin-300);
      margin-left: 0;
    }
  }

}
