@import "../../../screen.scss";
@import "../../../components/styles/gradient-text.mixin.scss";
@import "../../../components/styles/appear.animation.scss";

.deepSecurity {
    width: 100%;
    height: 100%;
    margin-top: var(--margin-1200);
    margin-bottom: var(--margin-1200);
    position: relative;

    display: grid;
    grid-template-columns: var(--width-600) var(--width-500);
    grid-gap: var(--width-gap);
    justify-content: center;
    align-items: flex-start;

    grid-row-gap: var(--padding-500);

    h2 {
        width: 100%;
        grid-column: 1 / 3;
        margin-bottom: 0;
    }
}

.deepSecurityDescription {
    align-items: center;
    justify-content: flex-end;

    p {
        max-width: var(--width-1100);
    }
}

.imageContainer {
    position: absolute;
    right: calc((100% - var(--width-400) - var(--width-700) - var(--width-gap)) / 2 );
    z-index: -1;

    &:before {
        --blur-size: 2rem;

        content: "";
        position: absolute;
        top: var(--blur-size);
        left: var(--blur-size);
        height: calc(100% - 2 * var(--blur-size));
        width: calc(100% - 2 * var(--blur-size));

        background: linear-gradient(to bottom,
                #090de7,
                #12f0f8,
                #12f0f8,
                #5e2fa7,
                #5e2fa7,
                #5e2fa7,
                #5e2fa7,
                #5e2fa7,
                #090de7);
        border-radius: calc(2 * var(--border-radius-400));
        z-index: -1;
        mix-blend-mode: screen;
        filter: blur(3rem);
    }

    img {
        height: auto;
        width: 50vw;

        max-width: var(--width-600);
        mask-image: radial-gradient(black 55%, transparent 80%);
        border-radius: var(--border-radius-400);
    }
}



@media (max-width: $screen-desktop) {
    .deepSecurity {
        grid-template-columns: var(--width-500) var(--width-200);
        grid-row-gap: var(--padding-300);
        margin-bottom: var(--margin-1000);
    }

    .imageContainer {
        right: 0;

        img {
            height: auto;
            width: 40vw;

            max-width: var(--width-800);
        }
    }
}

@media (max-width: $screen-laptop) {
    .deepSecurity {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: var(--width-600);
    }

    .imageContainer {
        margin-top: var(--margin-700);
        position: inherit;
        display: flex;
        width: fit-content;
        max-width: var(--width-500);

        img {
            width: 45vw;
            height: auto;
        }

        &:before {
            left: 2vw;
            width: calc(45vw - 2 * var(--blur-size));
        }
    }
}

@media (max-width: $screen-tablet) {
    .imageContainer {
        img {
            width: 60vw;
        }

        &:before {
            left: 5vw;
            width: calc(60vw - 2 * var(--blur-size));
        }
    }
}

@media (max-width: $screen-mobile) {
    .deepSecurity {
        margin-bottom: var(--margin-800);

        &:first-child {
            justify-content: flex-end;
            grid-column: 1;
        }
    }

    .imageContainer {
        img {
            width: 70vw;
        }

        &:before {
            left: 7vw;
            width: calc(70vw - 2 * var(--blur-size));
        }
    }
}
