.container {
  display: flex;
  align-items: center;
  font-size: var(--font-size-300);
  justify-content: center;
}

.icon {
  margin-right: var(--margin-100);
}
