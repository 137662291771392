@mixin ripple($size: 10rem) {
    position: relative;

    &::after {
        opacity: 0;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: calc(50% - $size / 2);
        left: calc(50% - $size / 2);
        right: 0;
        bottom: 0;
        content: '';
        height: $size;
        width: $size;
        background: #0460CC;
        border-radius: 100%;
    }
}

.animateRipple {
    &::after {
        animation: ripple-effect 0.8s cubic-bezier(.65, 0, .34, 1);
    }
}

@keyframes ripple-effect {
    from {
        opacity: 1;
        transform: scale3d(0.75, 0.75, 1);
    }

    to {
        opacity: 0;
        transform: scale3d(2, 2, 1);
    }
}