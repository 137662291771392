.container {
  width: 100%;
  max-width: 100%;
}

.icon {
  border-radius: 50%;
  border: 1px solid white;
  padding: 3px;
  margin-right: 0.8rem;
}

.checkboxBase {
  display: flex !important;
  flex-direction: row-reverse;
  justify-content: space-between !important;
  width: 100%;
  max-width: 100% !important;
  border-radius: 0.8rem;
  border: 1px solid var(--color-dark-500);
  margin: 0 !important;

  .icon {
    border-color: var(--color-text-500);

    svg {
      color: var(--color-text-500);
    }
  }

  &:hover {
    border-color: var(--color-dark-300);

    .icon {
      border-color: var(--color-text-300);

      svg {
        color: var(--color-text-300);
      }
    }
  }
}

.active {
  border-color: var(--color-dark-400);

  .icon {
    border-color: var(--color-text-400);

    svg {
      color: var(--color-text-400);
    }
  }
}

.checkboxWrapper {
  margin-right: 0.5rem !important;
  margin-top: 0;
  margin-left: auto;
}

.content {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.text {
  h5 {
    font-size: var(--font-size-400);
  }
  .description {
    font-size: var(--font-size-200);
    color: var(--color-text-450);
  }
}

.tag {
  margin-left: var(--margin-50);
}

.link {
  display: flex;
  justify-content: flex-end;

  margin-top: var(--margin-100);
  font-size: var(--font-size-200);
  a {
    color: var(--color-text-600);
  }
}
