@import "../screen.scss";

.main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-height: calc(100vh + var(--padding-900));
  width: 100%;
  height: 100%;

  max-width: var(--max-width);
  padding: 0 var(--width-gap);
  padding-top: var(--navigation-height);
  padding-bottom: var(--padding-500);


}

.content {
  width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: var(--width-800) var(--width-300);
  grid-gap: var(--width-gap);
  justify-content: center;

  grid-row-gap: var(--padding-500);

  h1 {
    width: 100%;
    margin-bottom: 0;

    font-size: var(--font-size-1180);
    font-weight: var(--font-black);
    line-height: var(--line-height-1165);
    letter-spacing: var(--letter-black-400);

    text-align: center;
    
  }

  p {
    margin-bottom: var(--margin-300);
    max-width: var(--width-800);

    font-size: var(--font-size-800);
    line-height: var(--line-height-800);
    
  }
}

@media (max-width: $screen-desktop) {

  .content {
    grid-template-columns: var(--width-500) var(--width-200);
    grid-row-gap: var(--padding-300);

    h1 {
      font-size: var(--font-size-1130);
      line-height: var(--line-height-1120);
      letter-spacing: var(--letter-black-500);
    }

    p {
      font-size: var(--font-size-600);
      line-height: var(--line-height-600);
    }
  }
}

@media (max-width: $screen-tablet) {
  .content {
    grid-template-columns: 2fr 1fr;

    h1 {
      text-align: left;
    }

  }
}


@media (max-width: $screen-mobile) {
  .main {
    padding-top: calc(var(--padding-700) + var(--navigation-height));
  }
  .content {
    grid-template-columns: 1fr;

    h1 {
      font-size: var(--font-size-1000);
      line-height: var(--line-height-1000);
      letter-spacing: var(--letter-black-500);
    }

    p {
      font-size: var(--font-size-500);
      line-height: var(--line-height-500);

      max-width: var(--width-500);
    }
  }
}
