@import "../../../components/styles/gradient-text.mixin.scss";
@import "../../../screen.scss";

.wrapper {
  position: relative;
  border-radius: 1.5rem;

  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;

    background: linear-gradient(to bottom right, #333399, #5073b8, #2657eb, #2657eb, hsl(229, 80%, 66%, 0.75));
    border-radius: calc(2 * var(--border-radius-400));

    background-size: 300% 300%;
    filter: blur(2rem);

    transition: all 0.5s ease;
    transform: scale(0.5);
  }
}

.container {
  background-color: var(--color-background-end);
  position: relative;
  overflow: hidden;
  padding: 2rem;
  border-radius: 1.5rem;

  width: 100%;
  height: 100%;

  z-index: 1;
}

.radial {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;

  &::after {
    content: '';
    position: absolute;
    background: radial-gradient(50% 50% at 50% 50%, var(--color-shade) 0, hsla(0, 0%, 50%, 0) 100%);
    height: 720px;
    left: calc(85% - 350.5px);
    top: -300px;
    width: 720px;
    opacity: 0.5;
  }
}

.content {
  display: flex;
  position: relative;
  z-index: 10;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  min-height: 13rem;
}

.text {
  &>h3 {
    margin-bottom: var(--margin-300);
    font-size: var(--line-height-1120);
    line-height: 1 !important;
  }

  p {
    max-width: var(--width-400);
  }

}

@media (max-width: $screen-laptop) {
  .text {
    &>h3 {
      font-size: var(--font-size-1050);
    }
  }
}

@media (max-width: $screen-mobile) {
  .text {
    &>h3 {
      font-size: var(--font-size-1000);
    }
  }
}