.baseLink {
  color: var(--color-text-350);
  text-decoration: none;

  // Not contain font size for easy reusing

  &:hover {
    color: var(--color-text-200);
  }

  &:active {
    color: var(--color-text-100);
  }
}

.internalLink {
  @extend .baseLink;
}

.externalLink {
  @extend .baseLink;
}

.linkWithIcon {
  @extend .baseLink;

  display: inline-flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;


  .icon {
    display: flex;
    margin-right: var(--margin-100);
  }

  .iconEnd {
    margin-right: 0;
  }

  svg {
    display: inline;
  }
}

.onlyIcon {
  .icon {
    margin-left: 0;
    margin-right: 0;
  }
}

.onlyText {
  display: inline;
}

.linkInText {
  text-decoration: underline;
}
