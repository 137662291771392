.content {
  width: 100%;
  display: flex;
  position: relative;
  z-index: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  box-shadow: #000 0 7px 35px 0;
  background-color: var(--color-background-start);
}

.footer {
  display: flex;
  justify-content: center;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-background-end);
}
