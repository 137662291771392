@import "../screen.scss";

.page {
  width: 100%;
  max-width: $screen-laptop;
  margin: 0 auto;

  margin-top: var(--margin-900);

  @media (max-width: $screen-mobile) {
    padding: 0 var(--padding-200);
  }
}
