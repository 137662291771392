.container {
  margin-bottom: var(--margin-400);

  h2 {
    font-size: var(--font-size-600);
    font-weight: var(--font-medium);
  }
}

.subHeader {
  font-size: var(--font-size-400);
  color: var(--color-text-400);
}
