@import "../../screen.scss";

.container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    max-width: var(--max-width);
    padding: 0 var(--width-gap);

    @media (max-width: $screen-small-mobile) {
        padding: 0 calc(var(--width-gap) / 2);
    }
}



@media (max-width: $screen-mobile) {

    .container {
        margin-bottom: var(--margin-800);
    }
}
