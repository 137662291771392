@import "../../../screen.scss";
@import "../../../components/styles/gradient-text.mixin.scss";
@import "../../../components/styles/appear.animation.scss";

.secOps {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 100vh;

    display: grid;
    grid-template-columns: var(--width-400) var(--width-700);
    grid-gap: var(--width-gap);
    justify-content: center;
    align-items: flex-start;

    grid-row-gap: var(--padding-500);

    h2 {
        width: 100%;
        grid-column: 1 / 3;
        margin-bottom: 0;
    }

    p {
        max-width: var(--width-1200);
    }
}

.imageContainer {
    z-index: -1;
    position: absolute;
    width: 50%;
    top: 55%;
}

.secOpsDescription {
    align-items: center;
    justify-content: flex-end;

    grid-column: 1 / 3;

    p {
        max-width: var(--width-1100);
    }
}

.secOpsCards {
    img {
        width: var(--width-400);
    }
}

.secOpsStatusIndicatorContainer {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    gap: var(--font-size-100);
}

.secOpsStatusIndicator {
    width: var(--font-size-700);
    height: var(--font-size-700);
    border-radius: 50%;
    background-color: var(--color-success-400);
}

.indicatorLabel {
    p {
        font-size: var(--font-size-1000);
        font-weight: var(--font-bold);
        line-height: var(--line-height-1120);
        letter-spacing: var(--letter-black-500);
    }
}

.indicatorLabelGreen {
    font-size: var(--font-size-1000);
    font-weight: var(--font-bold);
    line-height: var(--line-height-1120);
    letter-spacing: var(--letter-black-500);
    color: var(--color-success-400);
}

@media (max-width: $screen-desktop) {
    .secOps {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        grid-template-columns: 1fr;
        max-width: var(--width-600);

        &:first-child {
            justify-content: flex-end;
            grid-column: 1;
        }

        img {
            width: 100%;
        }
    }

    .secOpsImageCard {
        margin-top: 0;
        img {
            width: 40%;
            height: auto;
        }
    }

    .imageContainer {
        display: none;
    }
}

@media (max-width: $screen-laptop) {
    .secOpsImageCard {
        margin-top: 0;
    }
}

@media (max-width: $screen-tablet) {
    .secOps {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        grid-template-columns: 1fr;

        &:first-child {
            justify-content: flex-end;
            grid-column: 1;
        }
    }

    .secOpsImageCard {
        img {
            width: 100%;
        }
    }

    .imageContainer {
        position: relative;
        width: 100%;
    }
}
